import { render, staticRenderFns } from "./articleNotFound.vue?vue&type=template&id=6cea6659&scoped=true&"
import script from "./articleNotFound.vue?vue&type=script&lang=js&"
export * from "./articleNotFound.vue?vue&type=script&lang=js&"
import style0 from "./articleNotFound.vue?vue&type=style&index=0&id=6cea6659&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cea6659",
  null
  
)

export default component.exports